<template>
  <div class="d-flex flex-column-fluid flex-center">
    <div class="login-form login-forgot">
      <!--begin::Form-->
      <form class="form" @submit.stop.prevent="onSubmit">
        <div class="pb-13 pt-lg-0 pt-5">
          <h3
            class="
                    font-weight-bolder
                    text-dark
                    font-size-h4 font-size-h1-lg
                  "
          >
            {{ $t("AUTH.FORGOT.TITLE") }}
          </h3>
          <p class="text-muted font-weight-bold font-size-h4">
            {{ $t("AUTH.FORGOT.DESC") }}
          </p>
        </div>
        <div class="form-group">
          <input
            class="
                    form-control form-control-solid
                    h-auto
                    py-7
                    px-6
                    rounded-lg
                    font-size-h6
                  "
            :type="emailType"
            placeholder="Email"
            v-model="$v.form.email.$model"
            :state="validateState('email')"
            aria-describedby="input-1-live-feedback"
            autocomplete="on"
          />
        </div>
        <div class="form-group d-flex flex-wrap pb-lg-0">
          <button
            type="submit"
            class="
                    btn btn-primary
                    font-weight-bolder font-size-h6
                    px-8
                    py-4
                    my-3
                    mr-4
                  "
          >
            {{ $t("FORM.SUBMIT") }}
          </button>
        </div>
      </form>
    </div>
  </div>
</template>
<script>
import { SET_BREADCRUMB } from "@/store/modules/breadcrumbs";
import { FORGOT_PASSWORD } from "@/store/modules/user";
import { required } from "vuelidate/lib/validators";
import { validationMixin } from "vuelidate";

export default {
  mixins: [validationMixin],
  components: {},
  data() {
    return {
      emailType: "email",
      form: {
        email: ""
      }
    };
  },
  validations: {
    form: {
      email: {
        required
      }
      // password_confirm: {
      //   sameAsPassword: sameAs("password")
      // }
    }
  },
  // cengizkara@outlook.com

  methods: {
    validateState(title) {
      const { $dirty, $error } = this.$v.form[title];
      return $dirty ? !$error : null;
    },
    onSubmit() {
      console.log(this.form);
      // console.log(this.$v.form)
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }
      this.$store
        .dispatch(FORGOT_PASSWORD, {
          email: this.form.email
        })
        .then(() => {
          this.$nextTick(() => {
            this.$v.form.$reset();
          });
          this.form = {
            email: ""
          };
          this.$root.$bvToast.toast(this.$t("FORM.SUBMITTED"), {
            title: this.$t("FORM.SUCCESS_TITLE"),
            variant: "success",
            autoHideDelay: 5000
          });
          setTimeout(() => {
            this.$router.push({ name: "login" });
          }, 500);
        })
        .catch(err => {
          const msg = err?.response?.data?.message || this.$t("FORM.ERROR");
          this.$root.$bvToast.toast(msg, {
            title: this.$t("FORM.ERROR_TITLE"),
            variant: "danger",
            autoHideDelay: 5000
          });
        });
    }
    // changeemailType() {
    //   this.emailType = this.emailType === "password" ? "text" : "password";
    // }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: this.$t("ROUTES.CHANGE_PASSWORD") }
    ]);
  }
};
</script>
<style>
.call-button-container {
  margin: auto;
  vertical-align: middle;
  padding: 10px;
  background: #29a3d8;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  font-size: 17px;
  color: white;
}
</style>
